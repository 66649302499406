function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOT = '/';
const ROOTS_AUTH = '/auth';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  newPassword: path(ROOTS_AUTH, '/set-password'),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATHS = {
  root: ROOT,
  general: path(ROOT, 'general'),
  management: path(ROOT, 'management'),
  overview: path(ROOT, 'overview'),
  orders: {
    list: path(ROOT, 'orders'),
    detail: (orderId) => path(ROOT, `orders/${orderId}`),
    new: path(ROOT, 'orders/new'),
  },
  customers: {
    list: path(ROOT, 'customers'),
    info: (customerId) => path(ROOT, `customers/${customerId}/info`),
    users: (customerId) => path(ROOT, `customers/${customerId}/users`),
    transactions: (customerId) => path(ROOT, `customers/${customerId}/transactions`),
    fees: (customerId) => path(ROOT, `customers/${customerId}/fees`),
    orders: (customerId) => path(ROOT, `customers/${customerId}/orders`),
    reconciliations: (customerId) => path(ROOT, `customers/${customerId}/reconciliations`),
    reconciliation: {
      detail: (customerId, reconciliationId) =>
        path(ROOT, `customers/${customerId}/reconciliations/${reconciliationId}`),
      orderDetail: (customerId, reconciliationId, orderId) =>
        path(ROOT, `customers/${customerId}/reconciliations/${reconciliationId}/orders/${orderId}`),
    },

    settings: (customerId) => path(ROOT, `customers/${customerId}/settings`),
    new: path(ROOT, 'customers/new'),
  },
  users: {
    list: path(ROOT, 'users'),
    new: path(ROOT, 'users/new'),
  },
  transactions: {
    list: path(ROOT, 'transactions'),
    customer: (customerId) => path(ROOT, `transactions/customers/${customerId}`),
    detail: (transactionId) => path(ROOT, `transactions/${transactionId}`),
    new: (customerId) => path(ROOT, `transactions/customers/${customerId}/new`),
  },
  hedges: {
    list: path(ROOT, 'hedges'),
    detail: (orderId) => path(ROOT, `hedges/${orderId}`),
  },
  reconciliation: {
    list: path(ROOT, 'reconciliations'),
    customer: (customerId) => path(ROOT, `reconciliations/customers/${customerId}`),
    detail: (reconciliationId) => path(ROOT, `reconciliations/${reconciliationId}`),
    orderDetail: (reconciliationId, orderId) => path(ROOT, `reconciliations/${reconciliationId}/orders/${orderId}`),
  },
};
