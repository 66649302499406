import { createContext, useEffect, useReducer } from 'react';
import { Outlet } from 'react-router';
import axios from 'utils/axios';

const initialState = {
  customer: null,
  countdown: 0,
  offer: null,
  amount: null,
  loading: false,
  error: null,
  submission_data: null,
  requestPayload: null,
};

const handlers = {
  QUOTE_FETCH: (state) => ({
    ...state,
    loading: true,
  }),
  QUOTE_SUCCESS: (state, { payload }) => ({
    ...state,
    loading: false,
    countdown: payload.countdown,
    offer: payload.info.offer,
    submission_data: payload.info.submission_data,
    requestPayload: payload.request_payload,
  }),
  QUOTE_FAILED: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload.error,
  }),
  QUOTE_COUNTDOWN_UPDATE: (state, { payload }) => ({
    ...state,
    countdown: payload.countdown,
  }),
  SUBMIT_ORDER_FETCH: (state) => ({
    ...state,
    loading: true,
  }),
  SUBMIT_ORDER_SUCCESS: (state) => ({
    ...state,
    loading: false,
    error: null,
  }),
  SUBMIT_ORDER_FAILED: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload.error,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const OrdersContext = createContext({
  ...initialState,
});

function OrdersProvider() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getQuote = async (customerId, payload) => {
    dispatch({ type: 'QUOTE_FETCH' });

    try {
      const response = await axios.post(`/quotes/${customerId}`, payload);

      const info = response.data;
      const expiresAt = new Date(info.expires_at * 1000).getTime();
      const countdown = Math.floor((expiresAt - Date.now()) / 1000);

      dispatch({ type: 'QUOTE_SUCCESS', payload: { info, countdown, request_payload: payload } });
    } catch (error) {
      dispatch({ type: 'QUOTE_FAILED', payload: { error } });
    }
  };

  const submitOrder = async () => {
    dispatch({ type: 'SUBMIT_ORDER_FETCH' });

    try {
      await axios.post('/orders/', { data: state.submission_data });
      dispatch({ type: 'SUBMIT_ORDER_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'SUBMIT_ORDER_FAILED', payload: { error: error.message } });
    }
  };

  useEffect(() => {
    let interval;
    if (state.countdown > 0) {
      interval = setInterval(() => {
        dispatch({
          type: 'QUOTE_COUNTDOWN_UPDATE',
          payload: { countdown: state.countdown - 1 },
        });
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [state.countdown]);

  return (
    <OrdersContext.Provider
      value={{
        ...state,
        getQuote,
        submitOrder,
      }}
    >
      <Outlet />
    </OrdersContext.Provider>
  );
}

export { OrdersContext, OrdersProvider };
