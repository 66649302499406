import { InputSelectIcon } from './CustomIcons';

export default function Select() {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': {
            fontSize: '12px',
          },
        },
      },
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
    },
  };
}
