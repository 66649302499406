import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useSettings from 'hooks/useSettings';
import { createContext, useReducer } from 'react';
import { Outlet } from 'react-router';
import { PATHS } from 'routes/paths';
import axios from '../utils/axios';

const initialState = {
  title: 'Reconciliations',
  heading: 'Reconciliations',
  links: [
    { name: 'Management', href: PATHS.management },
    { name: 'Customers', href: PATHS.reconciliation.list },
  ],
  reconciliation: {
    loading: true,
    error: null,
    data: {
      id: '',
      customer_id: '',
    },
  },
  orderDetail: {
    loading: true,
    error: null,
    data: {
      id: '',
      customer: {},
    },
  },
};

const handlers = {
  RECONCILIATION_FETCH: (state) => ({
    ...state,
    reconciliation: { ...state.reconciliation, loading: true },
  }),
  RECONCILIATION_SUCCESS: (state, { payload: { reconciliation } }) => ({
    ...state,
    reconciliation: { loading: false, data: reconciliation },
  }),
  RECONCILIATION_FAILED: (state, { payload: { error } }) => ({
    ...state,
    reconciliation: { loading: false, error },
  }),
  ORDER_FETCH: (state) => ({
    ...state,
    orderDetail: { ...state.orderDetail, loading: true },
  }),
  ORDER_SUCCESS: (state, { payload: { order } }) => ({
    ...state,
    orderDetail: { loading: false, data: order },
  }),
  ORDER_FAILED: (state, { payload: { error } }) => ({
    ...state,
    orderDetail: { loading: false, error },
  }),
  SET_BREADCRUMB: (state, { payload: { title, heading, links } }) => ({
    ...state,
    title,
    heading,
    links,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const ReconciliationsContext = createContext({
  ...initialState,
  getReconciliationDetail: () => Promise.resolve(),
  getOrderDetail: () => Promise.resolve(),
});

function ReconciliationsProvider() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { themeStretch } = useSettings();

  const getReconciliationDetail = async (reconciliationId) => {
    dispatch({ type: 'RECONCILIATION_FETCH', payload: { reconciliationId } });

    try {
      const response = await axios.get(`/reconciliations/${reconciliationId}`);
      const reconciliation = response.data;
      dispatch({ type: 'RECONCILIATION_SUCCESS', payload: { reconciliation } });
    } catch (error) {
      dispatch({ type: 'RECONCILIATION_FAILED', payload: { error } });
    }
  };

  const getOrderDetail = async (orderId) => {
    dispatch({ type: 'ORDER_FETCH', payload: { orderId } });

    try {
      const response = await axios.get(`/orders/${orderId}`);
      const order = response.data;
      dispatch({ type: 'ORDER_SUCCESS', payload: { order } });
    } catch (error) {
      dispatch({ type: 'ORDER_FAILED', payload: { error } });
    }
  };

  const handleBreadcrumb = (title, heading, links) => {
    dispatch({ type: 'SET_BREADCRUMB', payload: { title, heading, links } });
  };

  return (
    <ReconciliationsContext.Provider
      value={{
        ...state,
        getReconciliationDetail,
        getOrderDetail,
        handleBreadcrumb,
      }}
    >
      <Page title={state.title || 'Reconciliations'}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={state.heading || 'Reconciliations'}
            links={state.links || [{ name: 'Management', href: PATHS.management }]}
          />
          <Outlet />
        </Container>
      </Page>
    </ReconciliationsContext.Provider>
  );
}

export { ReconciliationsContext, ReconciliationsProvider };
