import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
import { PATH_AUTH, PATH_PAGE } from '../routes/paths';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { user, customer, isCustomer, isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={PATH_AUTH.login} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  // user checks
  if (user.status !== 'active') {
    // TODO: sync this with backend
    return <Navigate to={PATH_PAGE.page403} />;
  }

  // customer checks
  if (isCustomer && customer?.status !== 'active') {
    return <Navigate to={PATH_PAGE.page403} />;
  }

  return <>{children}</>;
}
