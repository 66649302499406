export default function FormHelperText() {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          padding: '0 14px',
          margin: '8px 0 0 ',
        },
      },
    },
  };
}
