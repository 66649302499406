import { adminConfig, customerConfig } from 'layouts/main/navbar/NavConfig';
import { useMemo, useState } from 'react';
import useAuth from './useAuth';

const useNavConfig = () => {
  const [navConfig, setNavConfig] = useState([]);
  const { layout } = useAuth();

  useMemo(() => {
    if (layout === 'admin') {
      setNavConfig(adminConfig);
    } else {
      setNavConfig(customerConfig);
    }
  }, [layout]);

  return { navConfig };
};

export default useNavConfig;
