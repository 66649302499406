import { format, getTime, formatDistanceToNow } from 'date-fns';

export function fDate(date) {
  if (!date) return 'N/A';
  return format(new Date(date), 'dd.MM.yyyy');
}

export function fDateTime(date) {
  if (!date) return 'N/A';
  return format(new Date(date), 'dd.MM.yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd.MM.yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fDateToLocaleString(date) {
  return new Date(date).toLocaleString('tr-TR');
}
